import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Form} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';

import {formatDate, parseDateTime} from '../../utils/dateTimeFormater';
import moment from 'moment';

import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy HH:MM');

const DateTime = ({
    value,
    name,
    onChange,
    isDisabled,
    noLabel,
    popperPlacement = 'bottom-end',
    className,
    text,
    placeholder,
    isRequired,
    error,
    maxDate,
    minDate,
    isGrid,
    formRef,
}) => {
    const { t } = useTranslation();

    const ref = useRef(null);
    const popoverPlacement = useRef(popperPlacement);

    const getClassNames = () => {
        const classNames = [];

        if (error) {
            classNames.push('input-error');
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    useEffect(() => {
        isGrid &&
            ref &&
            ref.current &&
            ref.current.children[0] &&
            ref.current.children[0].children[0] &&
            ref.current.children[0].children[0].children[0] &&
            ref.current.children[0].children[0].children[0].focus();
    }, []);

    const getMinTime = minDate => {
        if (moment(minDate, 'DD.MM.YYYY').isSame(moment(value, 'DD.MM.YYYY'))) {
            return parseDateTime(minDate);
        }

        return setHours(setMinutes(new Date(), 0), 0);
    };

    const getMaxTime = maxDate => {
        if (moment(maxDate, 'DD.MM.YYYY').isSame(moment(value, 'DD.MM.YYYY'))) {
            return parseDateTime(maxDate);
        }

        return setHours(setMinutes(new Date(), 45), 23);
    };

    const handleClose = () => {
        if (isGrid) {
            setTimeout(() => {
                formRef &&
                    formRef.current &&
                    formRef.current.handleSubmit &&
                    formRef.current.handleSubmit();
            }, 100);
        }
    };

    const handleOpen = () => {
        if (ref && ref.current) {
            const { x } = ref.current.getBoundingClientRect();

            if (x < 300) {
                popoverPlacement.current = 'bottom-start';
            }
        }
    };

    return (
        <Form.Field className={noLabel ? 'no-label-datepicker' : undefined}>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div ref={ref} style={isGrid && { width: '150px' }}>
                <DatePicker
                    disabledKeyboardNavigation
                    placeholderText={t(placeholder)}
                    className={getClassNames()}
                    locale={localStorage.getItem('i18nextLng')}
                    disabled={isDisabled || false}
                    isClearable={!(isDisabled || isGrid)}
                    selected={parseDateTime(value || '')}
                    value={value}
                    maxDate={parseDateTime(maxDate || '')}
                    minDate={parseDateTime(minDate || '')}
                    minTime={getMinTime(minDate)}
                    maxTime={getMaxTime(maxDate)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption={t('Time')}
                    onCalendarClose={handleClose}
                    onCalendarOpen={handleOpen}
                    onChange={(date, e) => {
                        onChange(e, {
                            name: name,
                            value: date ? formatDate(date, 'dd.MM.yyyy HH:mm') : null,
                        });
                    }}
                    popperPlacement={popoverPlacement.current}
                    customInput={
                        <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                ' ',
                                /\d/,
                                /\d/,
                                ':',
                                /\d/,
                                /\d/,
                            ]}
                            keepCharPositions
                            guide
                        />
                    }
                />
            </div>

            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default DateTime;
