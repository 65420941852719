import React, {useCallback, useState} from 'react';
import {Form, Button} from 'semantic-ui-react';
import CardLayout from '../../components/CardLayout';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {TEXT_TYPE} from '../../constants/columnTypes';
import PasswordField from "../../components/BaseComponents/Password";
import {
    changePasswordRequest,
    errorSelector,
    getUserProfile,
    progressChangePasswordSelector
} from "../../ducks/profile";

const ChangePasswordForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);

    const progress = useSelector(state => progressChangePasswordSelector(state));
    const error = useSelector(state => errorSelector(state)) || {};

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button
                        color="blue"
                        disabled={notChangeForm}
                        loading={progress}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, notChangeForm, progress],
    );

    const handleSave = () => {
        dispatch(changePasswordRequest({
            form,
            t,
            callbackSuccess: () => {
                dispatch(getUserProfile({url: '/'}));
            }
        }));
    };

    const handleChange = useCallback(
        (event, {name, value}) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            setForm(form => ({
                ...form,
                [name]: value,
            }));
        },
        [notChangeForm, form],
    );

    return (
        <CardLayout
            title={t('set_new_password')}
            actionsFooter={getActionsFooter}
            closeDisabled
        >
            <Form className="user-form">
                <PasswordField
                    typeValue="password"
                    name="oldPassword"
                    text="oldPassword"
                    isRequired
                    value={form['oldPassword']}
                    type={TEXT_TYPE}
                    error={error['oldPassword']}
                    autoComplete="new-password"
                    onChange={handleChange}
                />
                <PasswordField
                    typeValue="password"
                    name="newPassword"
                    text="newPassword"
                    isRequired
                    value={form['newPassword']}
                    type={TEXT_TYPE}
                    error={error['newPassword']}
                    autoComplete="new-password"
                    onChange={handleChange}
                />
            </Form>
        </CardLayout>
    );
};

export default ChangePasswordForm;
