import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import store from '../store/configureStore';
import {ACCESS_TOKEN, logoutRequest, refreshTokenRequest} from '../ducks/login';

export const postman = axios.create({
    baseURL: '/api',
    paramsSerializer: params => qs.stringify(params, { indices: false }),
});

export const downloader = axios.create({
    baseURL: '/api',
});

postman.interceptors.response.use(
    resp => {
        return resp.data;
    },
    error => {
        const { data = {}, status } = error.response;
        const { error: errorText = '', message = '' } = data;

        console.log('data', JSON.stringify(errorText));

        (errorText || message) && toast.error(errorText ? JSON.stringify(errorText) : message || 'Ошибка!');

        if (status === 401) {
            store.dispatch(logoutRequest());
        }

        if (status === 403) {
            toast.error('Недостаточно прав доступа', {
                autoClose: false,
            })
        }

        if (status === 429 && !data.error) {
            toast.error('Слишком много запросов, попробуйте позже.', {
                autoClose: false,
            })
        }

        if (status === 500 && !data.error) {
            toast.error('Ошибка соединения', {
                autoClose: false,
            })
        }

        return Promise.reject(error);
    },
);

let refreshTimer;

export let setAccessToken = token => {
    if (token !== null) {
        postman.defaults.headers.common.Authorization = `Bearer ${token}`;
        downloader.defaults.headers.common.Authorization = `Bearer ${token}`;
        refreshToken(token);
    } else {
        delete postman.defaults.headers.common.Authorization;
        delete downloader.defaults.headers.common.Authorization;
        clearInterval(refreshTimer);
    }
};

const refreshToken = (token) => {
    refreshTimer && clearInterval(refreshTimer);
    refreshTimer = setInterval(() => {
        if (token) store.dispatch(refreshTokenRequest());
        else clearInterval(refreshTimer);
    }, 300000); //5min
}

window.addEventListener('storage', function(e) {
    if (e.key === ACCESS_TOKEN && e.newValue !== e.oldValue) setAccessToken(e.newValue);
});

document.addEventListener("visibilitychange", function() {
    if (document.visibilityState === 'visible') {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            store.dispatch(refreshTokenRequest());
            refreshToken(token);
        }
    } else refreshTimer && clearInterval(refreshTimer);
});


setAccessToken(localStorage.getItem(ACCESS_TOKEN));