import React from 'react';
import {Button, Icon, Label, Table} from 'semantic-ui-react';

const TableHeader = ({ statusList, t }) => {
    return (
        <>
            <Table.Row>
                <Table.HeaderCell className="">{t('field')}</Table.HeaderCell>
                <Table.HeaderCell className="dictionary-settings-column">{t('hidden')}</Table.HeaderCell>
                <Table.HeaderCell className="dictionary-settings-column">{t('required')}</Table.HeaderCell>
                <Table.HeaderCell className="dictionary-settings-column">{t('unique')}</Table.HeaderCell>
                <Table.HeaderCell className="dictionary-settings-column">{t('readOnly')}</Table.HeaderCell>
            </Table.Row>
        </>
    );
};

export default React.memo(TableHeader);
