import React, {useEffect, useRef} from 'react';
import {Form} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import {formatDate, parseDate} from '../../utils/dateTimeFormater';
import {useTranslation} from 'react-i18next';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

const Date = ({
    value,
    name,
    onChange,
    isDisabled,
    popperPlacement = 'bottom-end',
    noLabel,
    text,
    placeholder,
    className,
    isRequired,
    error,
    maxDate,
    minDate,
    isGrid,
    formRef,
}) => {
    const getClassNames = () => {
        const classNames = [];

        if (error) {
            classNames.push('input-error');
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    const { t } = useTranslation();

    const ref = useRef(null);
    const popoverPlacement = useRef(popperPlacement);

    useEffect(() => {
        isGrid &&
            ref &&
            ref.current &&
            ref.current.children[0] &&
            ref.current.children[0].children[0] &&
            ref.current.children[0].children[0].children[0] &&
            ref.current.children[0].children[0].children[0].focus();
    }, []);

    const handleClose = () => {
        if (isGrid) {
            setTimeout(() => {
                formRef &&
                    formRef.current &&
                    formRef.current.handleSubmit &&
                    formRef.current.handleSubmit();
            }, 100);
        }
    };

    const handleOpen = () => {
        if (ref && ref.current) {
            const { x } = ref.current.getBoundingClientRect();

            if (x < 300) {
                popoverPlacement.current = 'bottom-start';
            }
        }
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div ref={ref} style={isGrid && { width: '120px' }}>
                <DatePicker
                    disabledKeyboardNavigation
                    disabled={isDisabled || false}
                    placeholderText={t(placeholder)}
                    className={getClassNames()}
                    isClearable={!(isDisabled || isGrid)}
                    selected={parseDate((value || '').substring(0, 10))}
                    locale={localStorage.getItem('i18nextLng')}
                    dateFormat="dd.MM.yyyy"
                    maxDate={parseDate((maxDate || '').substring(0, 10))}
                    minDate={parseDate((minDate || '').substring(0, 10))}
                    onCalendarClose={handleClose}
                    onCalendarOpen={handleOpen}
                    onChange={(date, e) => {
                        onChange(e, { name: name, value: date ? formatDate(date) : null });
                    }}
                    popperPlacement={popoverPlacement.current}
                    customInput={
                        <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            keepCharPositions
                            guide
                        />
                    }
                />
            </div>
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default Date;
