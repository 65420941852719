import React from 'react';
import CardLayout from '../../../components/CardLayout';
import {useTranslation} from "react-i18next";
import FormField from "../../../components/BaseComponents";
import {useSelector} from "react-redux";
import {columnsCardSelector} from "../../../ducks/gridList";

const OtherCard = ({
    form,
    onChangeForm,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
}) => {
    const columns = useSelector(state => columnsCardSelector(state, name));

    return (
        <div>
            <CardLayout
                title={title}
                actionsFooter={actionsFooter}
                onClose={onClose}
                loading={loading}
            >
                <div className="ui form dictionary-edit">
                    {
                        columns.map(column => (
                            <FormField
                                {...column}
                                noScrollColumn={column}
                                key={column.name}
                                settings={settings[column.name]}
                                error={error[column.name]}
                                value={form[column.name]}
                                onChange={onChangeForm}
                            />
                        ))
                    }
                </div>
            </CardLayout>
        </div>
    );
};

export default OtherCard;
