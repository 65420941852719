import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Confirm, Dropdown } from 'semantic-ui-react';
import {
    addError,
    cardSelector,
    clearGridCard,
    createAndSendRequest,
    createGridCardRequest,
    editCardRequest,
    editProgressSelector,
    errorSelector,
    getCardRequest,
    isUniqueNumberRequest,
    progressSelector,
    selectCarrierRequest,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    progressActionNameSelector,
} from '../../ducks/gridActions';
import { ORDERS_GRID} from '../../constants/grids';
import { DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK, GRID_CARD_LINK } from '../../router/links';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import { getFieldsSettingRequest } from '../../ducks/fieldsSetting';
import { roleIdSelector } from '../../ducks/profile';
import OrderCard from './components/orderCard';
import OtherCard from './components/otherCard';
import Date from '../../components/BaseComponents/Date';
import { dateToString } from '../../utils/dateTimeFormater';
import { columnsCardSelector } from '../../ducks/gridList';
import { MULTI_SELECT_TYPE, SELECT_TYPE } from '../../constants/columnTypes';
import ParamsFromActions from './components/paramsFromActions';
import { errorMapping } from '../../utils/errorMapping';

const Card = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { name, id } = params;

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });
    let columns = useSelector(state => columnsCardSelector(state, name));
    let [dependentFields, setDependentFields] = useState({});
    let [formModal, setFormModal] = useState({ open: false, columns: [] });

    const title = useMemo(
        () =>
            id
                ? t(`edit_${name}`, {
                      number:form.orderNumber,
                      status: t(form.status),
                  })
                : t(`new_${name}`),
        [name, id, form],
    );

    const card = useSelector(state => cardSelector(state));
    const settings = useSelector(state => settingsFormSelector(state, card.status));
    const error = useSelector(state => errorSelector(state));
    const roleId = useSelector(state => roleIdSelector(state));

    useEffect(() => {
        dispatch(clearActions());
        id && loadCard();

        dispatch(
            getFieldsSettingRequest({
                forEntity: name,
                roleId,
            }),
        );

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, []);

    useEffect(() => {
        if (notChangeForm) {
            Object.keys(form).forEach(key => {
                if (form[key] !== card[key]) {
                    setNotChangeForm(false);
                }
            });
        }
    }, [form]);

    useEffect(() => {
        let obj = {};
        columns &&
            columns.length &&
            columns
                .filter(column => column.dependencies && column.dependencies.length)
                .forEach(column => {
                    column.dependencies.forEach(item => {
                        obj = {
                            ...obj,
                            [item]: [...(obj[item] || []), column.name],
                        };
                    });
                });

        setDependentFields(obj);
    }, [columns]);

    const loadCard = () => {
        id &&
            dispatch(
                getCardRequest({
                    name,
                    id,
                    callbackSuccess: card => {
                        setForm(card);
                        setNotChangeForm(true);
                        if (card.validationResult) {
                            card.validationResult &&
                                card.validationResult._errors &&
                                card.validationResult._errors.forEach(item => {
                                    dispatch(addError(item));
                                });
                        }
                    },
                }),
            );
        id &&
            dispatch(
                getActionsRequest({
                    name,
                    ids: [id],
                    isCard: true,
                }),
            );
        id && dispatch(getHistoryRequest(id));
    };

    const onClose = () => {
        const { state } = location;
        const { pathname, gridLocation } = state;

        history.replace({
            pathname: pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        });
    };

    const handleClose = isConfirm => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback(
        (e, { name, value }) => {
            setForm(prevState => {
                let formNew = {
                    ...prevState,
                    [name]: value,
                };

                if (dependentFields[name] && dependentFields[name].length) {
                    dependentFields[name].forEach(item => {
                        formNew = {
                            ...formNew,
                            [item]: '',
                        };
                    });
                }

                const autocompleteFields = columns.filter(
                    i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
                );

                if (autocompleteFields && autocompleteFields.length && value) {
                    autocompleteFields.forEach(autocompleteField => {
                        formNew = {
                            ...formNew,
                            [autocompleteField.name]:
                                value[autocompleteField.autoComplete[name]] || '',
                        };
                    });
                }

                return formNew;
            });
        },
        [columns],
    );

    const saveOrEditForm = callbackFun => {
        dispatch(
            editCardRequest({
                name,
                params: form,
                callbackSuccess: callbackFun
                    ? callbackFun
                    : () => {
                          if (form.id) {
                              setNotChangeForm(true);
                              loadCard();
                          } else {
                              handleClose();
                          }
                      },
            }),
        );
    };

    const createForm = () => {
        dispatch(
            createGridCardRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const handleSave = () => {
        setNotChangeForm(true);
        if (name === ORDERS_GRID && !form.id) {
            handleUniquenessCheck(saveOrEditForm);
        } else {
            saveOrEditForm();
        }
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const invokeAction = (actionName, model) => {
        if (model) {
            setFormModal({
                open: true,
                columns: model.columns,
                actionName,
                name,
                ids: [id],
                onClose: () => {
                    setFormModal({open: false})
                },
                backLoad: loadCard
            });
        } else {
            showConfirmation(
                `${t('Are you sure to complete')} "${t(actionName)}"?`,
                () => {
                    closeConfirmation();
                    dispatch(
                        invokeActionRequest({
                            ids: [id],
                            name,
                            actionName,
                            callbackSuccess: () => {
                                if (actionName.toLowerCase().includes('delete')) {
                                    onClose();
                                } else {
                                    loadCard();
                                }
                            },
                        }),
                    );
                },
                closeConfirmation,
            );
        }
    };

    const handleUniquenessCheck = callbackFunc => {
        dispatch(
            isUniqueNumberRequest({
                number: form.orderNumber,
                fieldName: 'orderNumber',
                errorText: t('number_already_exists'),
                callbackSuccess: callbackFunc,
            }),
        );
    };

    const handleCreateAndSend = () => {
        dispatch(
            createAndSendRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const loading = useSelector(state => progressSelector(state));
    const editLoading = useSelector(state => editProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const progressActionName = useSelector(state => progressActionNameSelector(state));
    const disableSave = useMemo(() => {
        return Boolean(progressActionName || notChangeForm);
    }, [progressActionName, notChangeForm]);


    const getActionsFooter = useCallback(() => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
    }, [form, disableSave, editLoading, name]);

    const goToCard = (gridName, cardId) => {
        const { state } = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const invokeCarrierSelection = () => {
        dispatch(
            selectCarrierRequest({
                ids: [form.id],
                callbackSuccess: () => {
                    setNotChangeForm(true);
                    loadCard();
                },
            }),
        );
    };

    const cardContent = () => {
        const params = {
            ...props,
            id,
            load: loadCard,
            name,
            form,
            title,
            settings,
            loading,
            uniquenessNumberCheck: handleUniquenessCheck,
            error,
            onClose: handleClose,
            onChangeForm: onChangeForm,
            actionsFooter: getActionsFooter,
        };

        const getContent = () => {
            switch (name) {
               // case ORDERS_GRID:
                //    return <OrderCard />;
                default:
                    return <OtherCard />;
            }
        };

        return React.cloneElement(getContent(), params);
    };

    return (
        <React.Fragment>
            {cardContent()}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <ParamsFromActions formModal={formModal} />
        </React.Fragment>
    );
};

export default Card;
