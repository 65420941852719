import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
            keepCharPositions
            guide
            pipe={autoCorrectedDatePipe}
        />
    );
}

const Time = (props) => {
    const { t } = useTranslation();
    const {
        value = '',
        name,
        onChange,
        isDisabled,
        noLabel,
        className,
        text,
        error,
        placeholder,
        isRequired,
        autoFocus,
        isGrid,
    } = props;

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
                <Input
                    {...props}
                    placeholder={t(placeholder)}
                    list={name}
                    className={className}
                    disabled={isDisabled}
                    name={name}
                    value={value}
                    error={error}
                    onChange={onChange}
                    autoFocus={autoFocus}
                    transparent={isGrid}
                    style={isGrid && value ? { width: `${(value.length + 1) * 8 + 24}px` } : null}
                    input={<MaskedInput
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        disabled={isDisabled}
                        keepCharPositions
                        guide
                        pipe={autoCorrectedDatePipe}
                    />}
                />
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};

export default Time;
