export default (fileName, fileType, fileContentBase64) => {
    fetch(`data:${fileType};base64,${fileContentBase64}`)
        .then(res => res.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([blob], { type: fileType }));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}
