import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    dictionariesHeaderSelector,
    dictionariesMenuSelector,
    gridsMenuSelector,
    roleSelector,
    userNameSelector,
    instructionsSelector,
    getSettingsCompaniesRequest
} from '../../ducks/profile';
import useReactRouter from 'use-react-router';
import { isAuthSelector, logoutRequest } from '../../ducks/login';
import { downloadDocumentRequest } from '../../ducks/documents';
import './style.scss';
import { DICTIONARY_LIST_LINK, GRID_LIST_LINK, PROFILE_LINK, REPORT_LINK } from '../../router/links';
import {
    dataLoadingRequest,
    getInstructionRequest,
    progressLoadingDataSelector,
    templateUploadRequest,
} from '../../ducks/loadingData';
import { downloader } from "../../utils/postman";
import downloadFile from "../../utils/downloadFile";
import TemplateSettings from "../LoadingData/TemplateSettings";

const Header = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { location, history } = useReactRouter();
    const fileUploader = useRef(null);

    const grids = useSelector(state => gridsMenuSelector(state));
    const dictionariesList = useSelector(state => dictionariesMenuSelector(state, t));
    const dictionariesMenu = useSelector(state => dictionariesHeaderSelector(state,t));
    const userName = useSelector(state => userNameSelector(state));
    const userRole = useSelector(state => roleSelector(state));
    const isAuth = useSelector(state => isAuthSelector(state));
    const loading = useSelector(state => progressLoadingDataSelector(state));
    const instructions = useSelector(state => instructionsSelector(state));

    let [activeItem, setActiveItem] = useState(location.pathname);
    let [currentTypeApi, setCurrentTypeApi] = useState(null);
    let [openTemplateSettings, setOpenTemplateSettings] = useState({ open: false });

    useEffect(() => {
        document.title = t("front_title");
     }, []);

    useEffect(() => {
        isAuth && dispatch(getSettingsCompaniesRequest());
    }, []);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location.pathname]);

    const logOut = () => {
        dispatch(logoutRequest());
    };

    const handleOpenProfile = () => {
        history.push(PROFILE_LINK);
    };

    const handleClickLoadData = (type, typeApi, fileName) => {
        if (type === 'unloading') {
            dispatch(templateUploadRequest({
                typeApi
            }));
        } else if (type === 'loading') {
            setCurrentTypeApi(typeApi);
            fileUploader && fileUploader.current.click();
        } else if (type === 'instruction') {
            dispatch(getInstructionRequest({
                fileName
            }))
        } else if (type === 'template_settings') {
            setOpenTemplateSettings({
                open: true,
                typeApi
            });
        }
    };

    const handleClickReport = (type) => {
        history.push(REPORT_LINK.replace(':type', type));
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            dataLoadingRequest({
                form: data,
                typeApi: currentTypeApi
            }),
        );
        setCurrentTypeApi(null);
        fileUploader.current.value = null;
    };

    return (
        <>
            {isAuth ? (
                <header>
                    <Menu pointing secondary fixed="top" style={{ paddingLeft: '12px' }}>
                        {/*<Menu.Item>LOGO</Menu.Item>*/}
                        {grids &&
                            grids.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item}
                                    as={Link}
                                    to={GRID_LIST_LINK.replace(':name', item)}
                                    name={item}
                                    active={activeItem.includes(item)}
                                >
                                    {t(item)}
                                </Menu.Item>
                            ))}
                        {dictionariesMenu && dictionariesMenu.length
                            ? dictionariesMenu.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item.name}
                                    as={Link}
                                    to={item.link}
                                    name={item.name}
                                    active={activeItem.includes(item.link)}
                                >
                                   {t(item.name)}
                                </Menu.Item>
                            ))
                            : null}
                        {(dictionariesList && (dictionariesList.groups || dictionariesList.others)) ? (
                            <Menu.Menu>
                                <Dropdown
                                    text={t('dictionaries')}
                                    item
                                    className={`${[
                                        ...(dictionariesList.groups || []).map(gr => gr.items).flat(), ...dictionariesList.others
                                    ].some(x => activeItem.includes(x.link)) && 'superActive'}`}
                                >
                                    <Dropdown.Menu>
                                        {dictionariesList.groups.map(group => (
                                                <Dropdown
                                                    item
                                                    className="link item"
                                                    key={group.name}
                                                    name={group.name}
                                                    text={t(group.name)}
                                                >
                                                    <Dropdown.Menu>
                                                        {group.items.map(item => (
                                                            <Dropdown.Item
                                                                key={item.name}
                                                                as={Link}
                                                                to={item.link}
                                                                active={activeItem.includes(item.link)}
                                                                name={item.name}
                                                            >
                                                                {t(item.name)}
                                                            </Dropdown.Item>))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ))
                                        }
                                        {dictionariesList.others.map(item => {
                                            return (
                                                <Dropdown.Item
                                                    key={item.name}
                                                    as={Link}
                                                    to={item.link}
                                                    active={activeItem.includes(item.link)}
                                                    name={item.name}
                                                >
                                                    {t(item.name)}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        <div className="header-support header-support_info">
                            <Dropdown
                                trigger={<Button style={{padding: '4px'}} icon='info' size="mini"/>}
                                icon={null}
                            >
                                <Dropdown.Menu>
                                    {instructions && instructions.map(item => (
                                        <Dropdown.Item
                                            key={item.name}
                                            name={item.name}
                                            text={t(item.name)}
                                            onClick={() => {
                                                dispatch(downloadDocumentRequest({id: item.value}))
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="header-support">
                            {userName && userRole ? (
                                <Menu.Menu>
                                    <Dropdown text={`${userName} (${userRole})`} item>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleOpenProfile}>
                                                {t('profile_settings')}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={logOut}>
                                                {t('exit')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            ) : null}
                        </div>
                    </Menu>
                </header>
            ) : null}
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            <TemplateSettings {...openTemplateSettings} onClose={() => setOpenTemplateSettings({ open: false })} />
        </>
    );
};
Header.propTypes = {};

export default Header;
