export default [
    {
        text: 'Русский',
        value: 'ru',
        flag: 'ru',
    },
    {
        text: 'English',
        value: 'en',
        flag: 'us',
    },
];
