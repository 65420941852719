import React from 'react'
import FormField from '../../../components/BaseComponents';

const CardContent = ({ columns, error, form, handleChange }) => {

console.debug('error', error);

    return (
        <div className="ui form dictionary-edit">
            {columns.map(column => {
                let newColumn = {
                    ...column,
                };

                if (column.dependencies && column.dependencies.length) {
                    let sourceParams = {};

                    column.dependencies.forEach(item => {
                        sourceParams = {
                            ...sourceParams,
                            [item]:
                                form[item] && typeof form[item] === 'object'
                                    ? form[item].value
                                    : form[item],
                        };
                    });

                    newColumn = {
                        ...newColumn,
                        sourceParams,
                    };
                }
                return (
                    <FormField
                        {...newColumn}
                        noScrollColumn={column}
                        key={column.name}
                        error={error[column.name]}
                        value={form[column.name]}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};


export default CardContent
