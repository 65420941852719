import { all, put, takeEvery, take, spawn, delay, cancelled, cancel, fork, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {downloader, postman} from '../utils/postman';
import downloadFile from "../utils/downloadFile";
import { toast } from 'react-toastify';

const TYPE_API = 'reports';

//*  TYPES  *//

const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

const REPORT_EXPORT_TO_EXCEL_REQUEST = 'REPORT_EXPORT_TO_EXCEL_REQUEST';
const REPORT_EXPORT_TO_EXCEL_SUCCESS = 'REPORT_EXPORT_TO_EXCEL_SUCCESS';
const REPORT_EXPORT_TO_EXCEL_ERROR = 'REPORT_EXPORT_TO_EXCEL_ERROR';

const SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_REQUEST = 'SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_REQUEST';
const SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_SUCCESS = 'SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_SUCCESS';
const SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_ERROR = 'SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_ERROR';

const REPORT_EXPORT_SERVER_PLAN_REQUEST = 'REPORT_EXPORT_SERVER_PLAN_REQUEST';
const REPORT_EXPORT_SERVER_PLAN_SUCCESS = 'REPORT_EXPORT_SERVER_PLAN_SUCCESS';
const REPORT_EXPORT_SERVER_PLAN_ERROR = 'REPORT_EXPORT_SERVER_PLAN_ERROR';

const REPORT_EXPORT_SERVER_ACTUAL_REQUEST = 'REPORT_EXPORT_SERVER_ACTUAL_REQUEST';
const REPORT_EXPORT_SERVER_ACTUAL_SUCCESS = 'REPORT_EXPORT_SERVER_ACTUAL_SUCCESS';
const REPORT_EXPORT_SERVER_ACTUAL_ERROR = 'REPORT_EXPORT_SERVER_ACTUAL_ERROR';

const REPORT_EXPORT_SERVER_ACTUAL_DRIVER_REQUEST = 'REPORT_EXPORT_SERVER_ACTUAL_DRIVER_REQUEST';
const REPORT_EXPORT_SERVER_ACTUAL_DRIVER_SUCCESS = 'REPORT_EXPORT_SERVER_ACTUAL_DRIVER_SUCCESS';
const REPORT_EXPORT_SERVER_ACTUAL_DRIVER_ERROR = 'REPORT_EXPORT_SERVER_ACTUAL_DRIVER_ERROR';

const GET_REPORT_CONFIGURATION_COLUMNS_REQUEST = 'GET_REPORT_CONFIGURATION_COLUMNS_REQUEST';
const GET_REPORT_CONFIGURATION_COLUMNS_SUCCESS ='GET_REPORT_CONFIGURATION_COLUMNS_SUCCESS';
const GET_REPORT_CONFIGURATION_COLUMNS_ERROR = 'GET_REPORT_CONFIGURATION_COLUMNS_ERROR';

const CHANGE_REPORT_SELECTED_COLUMNS = 'CHANGE_REPORT_SELECTED_COLUMNS';
const SAVE_REPORT_SELECTED_COLUMNS = 'SAVE_REPORT_SELECTED_COLUMNS';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    columns: [],
    config: [],
    progress: false,
    exportProgress: false,
    configurationColumns: [],
    selectedColumns: [],
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_REPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                ...payload,
            };
        case GET_REPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case REPORT_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_TO_EXCEL_SUCCESS:
        case REPORT_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_SUCCESS:
        case SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case REPORT_EXPORT_SERVER_PLAN_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_SERVER_PLAN_SUCCESS:
        case REPORT_EXPORT_SERVER_PLAN_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case REPORT_EXPORT_SERVER_ACTUAL_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_SERVER_ACTUAL_SUCCESS:
        case REPORT_EXPORT_SERVER_ACTUAL_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case REPORT_EXPORT_SERVER_ACTUAL_DRIVER_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_SERVER_ACTUAL_DRIVER_SUCCESS:
        case REPORT_EXPORT_SERVER_ACTUAL_DRIVER_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case GET_REPORT_CONFIGURATION_COLUMNS_REQUEST:
        case GET_REPORT_CONFIGURATION_COLUMNS_ERROR:
            return {
                ...state,
                configurationColumns: [],
            };
        case GET_REPORT_CONFIGURATION_COLUMNS_SUCCESS:
            return {
                ...state,
                configurationColumns: payload
            };
        case CHANGE_REPORT_SELECTED_COLUMNS:
            return {
                ...state,
                selectedColumns: payload,
            };
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getReportRequest = (payload) => {
    return {
        type: GET_REPORT_REQUEST,
        payload
    }
};

export const reportExportToExcelRequest = payload => {
    return {
        type: REPORT_EXPORT_TO_EXCEL_REQUEST,
        payload
    }
};

export const shipmentRegistryExportToExcelRequest = payload => {
    return {
        type: SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_REQUEST,
        payload
    }
};

export const reportExportServerPlanRequest = payload => {
    return {
        type: REPORT_EXPORT_SERVER_PLAN_REQUEST,
        payload
    }
};

export const reportExportServerActualRequest = payload => {
    return {
        type: REPORT_EXPORT_SERVER_ACTUAL_REQUEST,
        payload
    }
};

export const reportExportServerActualDriverRequest = payload => {
    return {
        type: REPORT_EXPORT_SERVER_ACTUAL_DRIVER_REQUEST,
        payload
    }
};

export const getReportConfigurationColumnsRequest = (payload) => {
    return {
        type: GET_REPORT_CONFIGURATION_COLUMNS_REQUEST,
        payload
    }
};

export const changeReportSelectedColumnsRequest = payload => {
    return {
        type: CHANGE_REPORT_SELECTED_COLUMNS,
        payload
    }
};

export const saveReportSelectedColumnsRequest = payload => {
    return {
        type: SAVE_REPORT_SELECTED_COLUMNS,
        payload
    }
};

//*  SELECTORS *//

const stateSelector = state => state.reports;
export const reportSelector = createSelector(stateSelector, state => state.data);

export const columnsSelector = createSelector(stateSelector, state => {
    return state.config.filter(item => state.columns.map(column => column.toLowerCase()).includes(item.name.toLowerCase()))
});

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const exportProgressSelector = createSelector(stateSelector, state => state.exportProgress);

export const configurationColumnsSelector = createSelector([stateSelector, (state, selectedColumns) => selectedColumns], (state, selectedColumns = []) => {
    return (state.configurationColumns || []).filter(i => !selectedColumns.map(m => m.name).includes(i.name)).map(item => ({
        ...item,
        isMarker: item.isRequired,
    }))
});
export const selectedColumnsSelector = createSelector([stateSelector, columnsSelector], (state, columns) => {
    return state.selectedColumns && state.selectedColumns.length ? state.selectedColumns : columns.filter(i => i.isRequired)
});

//*  SAGA  *//

function* getReportSaga({ payload }) {
    try {
        const {type, params} = payload;

        const config = yield postman.get(`/${TYPE_API}/${type}/reportConfiguration`);

        const result = yield postman.post(`/${TYPE_API}/${type}/get`, params);

        yield put({
            type: GET_REPORT_SUCCESS,
            payload: {
                ...result,
                config: config.columns
            }
        })
    } catch (e) {
        yield put({
            type: GET_REPORT_ERROR
        })
    }
}

function* reportExportToExcelSaga({ payload }) {
    try {
        const {type, params} = payload;

        const result = yield downloader.post(`/${TYPE_API}/${type}/export`, params, { responseType: 'blob' });

        downloadFile(result);

        yield put({type: REPORT_EXPORT_TO_EXCEL_SUCCESS})
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_TO_EXCEL_ERROR
        })
    }
}

function* shipmentRegistryExportToExcelSaga({ payload }) {
    try {
        const {type, params} = payload;
        const saveConfig = yield postman.get(`/userSettings/${type}`);

        yield put({
            type: CHANGE_REPORT_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });
        
        const selected = yield select(selectedColumnsSelector);
        const columns = selected.map(item => item.name);
        const result = yield downloader.post(`/${TYPE_API}/${type}/exportExcel`, {...params, columns: columns},  { responseType: 'blob' });

        downloadFile(result);

        yield put({type: SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_SUCCESS})
    } catch (e) {
        yield put({
            type: SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_ERROR
        })
    }
}

function* reportExportServerPlanSaga({ payload }) {
    try {
        const {type, params} = payload;
        const saveConfig = yield postman.get(`/userSettings/${type}`);

        yield put({
            type: CHANGE_REPORT_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });

        const selected = yield select(selectedColumnsSelector);
        const columns = selected.map(item => item.name);
        const result = yield postman.post(`/${TYPE_API}/${type}/exportServerPlan`, {...params, columns: columns});

        if (!result.isError) {
            toast.info(result.message);
            yield put({type: REPORT_EXPORT_SERVER_PLAN_SUCCESS})
        } else {
            toast.error(result.message);
            yield put({
                type: REPORT_EXPORT_SERVER_PLAN_ERROR
            })
        }

        
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_SERVER_PLAN_ERROR
        })
    }
}

function* reportExportServerActualSaga({ payload }) {
    try {
        const {type, params} = payload;
        const saveConfig = yield postman.get(`/userSettings/${type}`);

        yield put({
            type: CHANGE_REPORT_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });

        const selected = yield select(selectedColumnsSelector);
        const columns = selected.map(item => item.name);
        const result = yield postman.post(`/${TYPE_API}/${type}/exportServerActual`, {...params, columns: columns});

        if (!result.isError) {
            toast.info(result.message);
            yield put({type: REPORT_EXPORT_SERVER_ACTUAL_SUCCESS})
        } else {
            toast.error(result.message);
            yield put({
                type: REPORT_EXPORT_SERVER_ACTUAL_ERROR
            })
        }

        
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_SERVER_ACTUAL_ERROR
        })
    }
}

function* reportExportServerActualDriverSaga({ payload }) {
    try {
        const {type, params} = payload;
        const saveConfig = yield postman.get(`/userSettings/${type}`);

        yield put({
            type: CHANGE_REPORT_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });

        const selected = yield select(selectedColumnsSelector);
        const columns = selected.map(item => item.name);
        const result = yield postman.post(`/${TYPE_API}/${type}/exportServerActualDriver`, {...params, columns: columns});

        if (!result.isError) {
            toast.info(result.message);
            yield put({type: REPORT_EXPORT_SERVER_ACTUAL_DRIVER_SUCCESS})
        } else {
            toast.error(result.message);
            yield put({
                type: REPORT_EXPORT_SERVER_ACTUAL_DRIVER_ERROR
            })
        }

        
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_SERVER_ACTUAL_DRIVER_ERROR
        })
    }
}

function* getReportConfigurationColunmsSaga({payload}) {
    try {
        const {typeApi} = payload;
         const result = yield postman.get(`reports/${typeApi}/configuration`);

         yield put({
             type: GET_REPORT_CONFIGURATION_COLUMNS_SUCCESS,
             payload: result.columns
         });

        const saveConfig = yield postman.get(`/userSettings/${typeApi}`);

        yield put({
            type: CHANGE_REPORT_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });

    } catch (e) {
        yield put({
            type: GET_REPORT_CONFIGURATION_COLUMNS_ERROR
        })
    }
}

function* saveReportSelectedColumnsSaga({ payload }) {
    try {
        const { typeApi, callbackSuccess } = payload;
        const columns = yield select(selectedColumnsSelector);
        const result = yield postman.post(`/userSettings/${typeApi}`, {
            value: JSON.stringify(columns),
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        console.log("___error", e)
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_REPORT_REQUEST, getReportSaga),
        takeEvery(GET_REPORT_CONFIGURATION_COLUMNS_REQUEST, getReportConfigurationColunmsSaga),
        takeEvery(SAVE_REPORT_SELECTED_COLUMNS, saveReportSelectedColumnsSaga),
        takeEvery(SHIPMENT_REGISTRY_EXPORT_TO_EXCEL_REQUEST, shipmentRegistryExportToExcelSaga),
        takeEvery(REPORT_EXPORT_SERVER_PLAN_REQUEST, reportExportServerPlanSaga),
        takeEvery(REPORT_EXPORT_SERVER_ACTUAL_REQUEST, reportExportServerActualSaga),
        takeEvery(REPORT_EXPORT_SERVER_ACTUAL_DRIVER_REQUEST, reportExportServerActualDriverSaga),
        takeEvery(REPORT_EXPORT_TO_EXCEL_REQUEST, reportExportToExcelSaga),
    ])
}
